// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Events = {
    id: 'Events',
    title: <FormattedMessage id="Events" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default Events ;
