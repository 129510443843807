// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconCalculator } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalculator
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Calculators = {
    id: 'Calculators',
    title: <FormattedMessage id="Calculators" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
             // id:'Calc',
            // title: <FormattedMessage id="Calculators" />,
            // type: 'item',
            // icon:icons.IconCalculator,
            // url: '/calculator',
            // breadcrumbs: false
        }
    ]
    
};

export default Calculators;
