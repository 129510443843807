// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconCalculator,
  IconHomeStats,
  IconDeviceWatchStats,
  IconUvIndex,
  IconBrandGoogleAnalytics,
  IconBrandFinder
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconCalculator,
  IconHomeStats,
  IconDeviceWatchStats,
  IconUvIndex,
  IconBrandGoogleAnalytics,
  IconBrandFinder
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const QIChamp = {
  id: "qichamp",
  title: <FormattedMessage id="Quantum Investing Champion" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "qichampNew",
      title: (
        <>
          QIC 2025{" "}
          <sup
            style={{
              color: "green",
              fontSize: "14px",
              border: "1px solid green",
              padding: "2px 4px",
              borderRadius: "15px",
            }}
          >
            <b>Live</b>
          </sup>
        </>
      ),
      type: "item",
      icon: icons.IconBrandGoogleAnalytics,
      url: "/qic/dashboard2025?year=2025",
      breadcrumbs: false,
    },
    {
      id: "qichamp",
      title: <FormattedMessage id="QIC 2024" />,
      type: "item",
      icon: icons.IconBrandGoogleAnalytics,
      url: "/qic/dashboard?year=2024", 
      breadcrumbs: false,
    },
  ],
};
export default QIChamp;
