import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const CalculatedIndex = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const anchorRef = useRef(null);

    const handleNavigate = () => {
        navigate('/Calculator/PortfolioSizing');
    };

    return (
        
            <Box
                sx={{
                    ml: 1,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 1
                    }
                }}
            >
                <Tooltip title="Calculator">
                <IconButton
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    onClick={handleNavigate}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <CalculateIcon stroke={1.5} size="20px" />
                </IconButton>
                </Tooltip>
            </Box>
        
    );
};

export default CalculatedIndex;
