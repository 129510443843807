// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const 	Resources = {
    id: 'Resources',
    title: <FormattedMessage id="Resources" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default Resources ;
