import { useEffect, useRef, useState } from 'react';
import React from "react";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    useMediaQuery,
    IconButton,
    Divider,
    Typography,
    Grid
} from '@mui/material';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
// assets
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { useSelector } from "react-redux";
import { setDemovideoLink } from 'store/slices/Demovideo';
import CloseIcon from '@mui/icons-material/Close';
import { GetDemovideolinks} from 'store/slices/Demovideo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:"#0666e5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Index = () => {

    const theme = useTheme();
    const demovideolinks= useSelector(setDemovideoLink);
    const anchorRef = useRef(null);
    const [openData,setopnData]=useState(false);
      useEffect(()=>{
        GetDemovideolinks();
      },[openData])


    return (
        <>
            <Box
                sx={{
                    ml: 1,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 1
                    }
                }}
            >
                <IconButton
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={openData ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={()=>setopnData(true)} 
                    color="inherit"
                >
                    <SmartDisplayIcon  size={24} />
                </IconButton>
            </Box>
            <Dialog
                open={openData}
                TransitionComponent={Transition}
                keepMounted
                 onClose={()=>setopnData(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Grid container spacing={2} >
                        <Grid item xl={10} xs={10}>
                          {"Demo Video"}
                        </Grid>
                        <Grid item xl={2} xs={2} display='flex' justifyContent='end'>
                            <IconButton color='error' onClick={()=>setopnData(false)} size='small'>
                               <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    
                    
                    </DialogTitle>
                <Divider/>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Video link</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {demovideolinks.payload.demovideolink.GetDemovideoData.length!==0 ? demovideolinks.payload.demovideolink.GetDemovideoData.map((row,index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                <Typography variant='h5'> {index+1} </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                 <Typography variant='h5'> {row.name}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                 <Typography variant='h5'>
                    <Link href={row.video_link} underline="hover" target="_blank">    
                        Watch now
                    </Link>
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))
         :
         <StyledTableRow key={1}>
         <StyledTableCell component="th" scope="row">
             {1}
         </StyledTableCell>
         <StyledTableCell  colSpan={2} component="th" scope="row">
             <Typography variant='h5' >Data not found!</Typography>
         </StyledTableCell>
        </StyledTableRow>
         }
        </TableBody>
      </Table>
    </TableContainer>
    <Divider/>
                </DialogContentText>
                </DialogContent>
            </Dialog>
            

        </>
    );
};

export default Index;
