// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const WatchlistSearch = {
    id: 'Watchlist Search',
    title: <FormattedMessage id="Watchlist Search" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default WatchlistSearch ;
