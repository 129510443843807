import { FormattedMessage } from 'react-intl';

// assets
import { IconCalculator, IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

 
// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalculator
};


const QuantumInvestingChampion = {
    id: 'qichampion',
    title: <FormattedMessage id="Quantum Investing Champion" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'qic',
            title: <FormattedMessage id="QIC" />,
            type: 'item',
            url: '/qic/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
}
    ]
};

export default QuantumInvestingChampion;