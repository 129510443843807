// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const QuantumQuiz = {
    id: 'Quantum Quiz',
    title: <FormattedMessage id="Quantum Quiz" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default QuantumQuiz;
