// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconKey,IconBasket, IconReceipt2, IconBug, IconBellRinging,IconChecklist, IconPhoneCall, IconQuestionMark, IconShieldLock ,IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconKey,
    IconReceipt2,
    IconBug,
    IconBellRinging,
    IconPhoneCall,
    IconQuestionMark,
    IconShieldLock,
    IconBasket,
	IconChecklist
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'others',
  
    title: <FormattedMessage id="Help" />,
    type: 'group',
    children: [
        // {
        //     id: 'Resources',
        //     title: <FormattedMessage id="Resources" />,
        //     type: 'item',
        //     url: '/Resources',
        //     icon: icons.IconShieldLock,
        //     breadcrumbs: false
        // },
		{
            id: 'Onboarding',
            title: <FormattedMessage id="On Boarding Checklist" />,
            type: 'item',
            url: '/Onboarding',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        {
            id: 'Help Tickets',
            title: <FormattedMessage id="Help/Tickets" />,
            type: 'item',
            url: '/contact-us',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'Help Response',
            title: <FormattedMessage id="Help Response" />,
            type: 'item',
            url: '/HelpResponse',
            icon: icons.IconSitemap,
            breadcrumbs: false
        }
    ]
};

export default other;
