// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const CoreAudit = {
    id: 'Core Audit',
    title: <FormattedMessage id="Core Audit" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default CoreAudit ;
