import { createSlice } from "@reduxjs/toolkit";
import { API_URL } from "config";
import axios from "axios";
import { dispatch } from '../index';
 


const initialState={
    attendance:0
}

const Attendanceevent=createSlice({
    name:'attendance',
    initialState,
    reducers:{
         setattendancevalues(state,action){
                 state.attendance=action.payload
         },
         
    }
})

export async function Attendancecalculet(userId,groupId){
    const responce= await axios.post(API_URL +'/api/event/AttendanceChartData',{userId,groupId});
      dispatch(setattendancevalues(responce.data.attended));
      
    }

export const selectAttendance=(state)=>state.attendance
export const{setattendancevalues} = Attendanceevent.actions;

export default Attendanceevent.reducer