import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import img from './QuotesSvg/success.png';
import useAuth from "hooks/useAuth";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Axios from "axios";

const QuoteDaily = () => {
  const [open, setOpen] = useState(false);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const { Getquotes, userQuotesCheck, userQuotesRecord } = useAuth();  // Assuming `user` contains user info like ID
  const Usersid = window.localStorage.getItem('users');
  const val = JSON.parse(Usersid);
  const userId = val.id;
  console.log(userId);
  // Default fallback quote
  const fallbackQuote = {
    quotes: "The stock market is a device for transferring money from the impatient to the patient.",
    author: "Warren Buffett",
    img_url: img,
  };

   const GetquotesCall = async () => {
    try {
      setLoading(true);
      const res = await Getquotes();
      setQuote(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch quote:", error);
      setQuote(fallbackQuote);
      setLoading(false);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleClickOpen = () => {
    GetquotesCall();  // Fetch a quote when opening the dialog manually
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRead = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      await userQuotesRecord(userId, today); // Record quote read status in backend
      setOpen(false); // Close dialog after recording
    } catch (error) {
      console.error("Failed to record quote read status:", error);
    }
  };

  // Check if quote has been shown today on component mount
  // useEffect(() => {
  //   const checkIfQuoteShown = async () => {
  //     const today = new Date().toISOString().split('T')[0];
  //     try {
  //       const response = await userQuotesCheck(userId, today); // Check quote read status
  //       if (!response.data.shown) { // Show quote if not already shown
  //         GetquotesCall(); // Fetch and display quote
  //         setOpen(true);
  //       }
  //     } catch (error) {
  //       console.error("Error checking quote status:", error);
  //       // Optionally handle error by showing quote or retrying
  //     }
  //   };

  //   checkIfQuoteShown();
  // }, [userId]);


  return (
    <Box sx={{ ml: 0, mt: 0.5, mr: 1 }}>
      <Tooltip title="Daily Quotes">
        <svg
          onClick={handleClickOpen}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: "pointer" }}
        >
          <rect width="32" height="32" rx="16" fill="#0666e5" />
          <path
            d="M14.6253 10.5361L14.0365 9.63086C9.96301 12.369 7.69995 15.6959 7.69995 18.434C7.69995 21.0816 9.64655 22.3265 11.2984 22.3265C13.3804 22.3265 14.8512 20.5609 14.8512 18.7056C14.8512 17.1442 13.8555 15.8088 12.5201 15.3106C12.1356 15.1745 11.7735 15.0615 11.7735 14.4054C11.7735 13.5682 12.3847 12.3241 14.6253 10.5361ZM23.6095 10.5361L23.0207 9.63086C18.9921 12.369 16.6841 15.6959 16.6841 18.434C16.6841 21.0816 18.6756 22.3265 20.3275 22.3265C22.4319 22.3265 23.9259 20.5609 23.9259 18.7056C23.9259 17.1442 22.9077 15.8088 21.5267 15.3106C21.1422 15.1745 20.8025 15.0615 20.8025 14.4054C20.8025 13.5682 21.4362 12.3234 23.6087 10.5354L23.6095 10.5361Z"
            fill="white"
          />
        </svg>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent sx={{ padding: 0, borderRadius: 2 }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 0,
              boxShadow: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                mx: 2,
                my: 1,
                border: 1.5,
                borderColor: "#D3D0D7",
                borderRadius: "8px",
                borderStyle: "solid 1px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="20" fill="#0666E5" />
                  <path
                    d="M18.2817 13.1686L17.5457 12.0371C12.4538 15.4598 9.625 19.6184 9.625 23.041C9.625 26.3505 12.0582 27.9066 14.1231 27.9066C16.7256 27.9066 18.5641 25.6997 18.5641 23.3805C18.5641 21.4288 17.3194 19.7596 15.6502 19.1368C15.2252 18.9666 14.8223 18.8254 14.8223 18.0053C14.8223 16.9588 15.6147 15.4022 18.2817 13.1686ZM29.5119 13.1686L28.7759 12.0371C23.7112 15.4598 20.7994 19.6184 20.7994 23.041C20.7994 26.3505 23.2683 27.9066 25.3331 27.9066C27.9752 27.9066 29.9269 25.6997 29.9269 23.3805C29.9269 21.4288 28.6404 19.7596 26.9082 19.1368C26.4275 18.9666 25.9849 18.8254 25.9849 18.0053C25.9849 16.9588 26.7779 15.4022 29.5103 13.1668L29.5119 13.1686Z"
                    fill="white"
                  />
                </svg>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: isMobile
                      ? "1.15rem"
                      : isTablet
                      ? "1.25rem"
                      : "1.5rem",
                    lineHeight: "1.30",
                    textAlign: isMobile ? "center" : "left",
                  }}
                >
                  Quote of the Day
                </Typography>
              </Box>
              {/* <IconButton
                onClick={handleClose}
                aria-label="close"
                sx={{ color: "red" }}
              >
                <CloseIcon />
              </IconButton> */}
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  p: 2,
                  mx: 2,
                  my: 1,
                  border: 1.5,
                  borderColor: "#D3D0D7",
                  borderRadius: "8px",
                  borderStyle: "solid 1px",
                  gap: 2,
                  width: "auto",
                }}
              >
                <Box sx={{ flex: 1, pr: isMobile ? 0 : 2 }}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography
                        variant={isMobile ? "h4" : "h3"}
                        sx={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: isMobile
                            ? "1.15rem"
                            : isTablet
                            ? "1.25rem"
                            : "1.5rem",
                          lineHeight: "1.30",
                          textAlign: isMobile ? "center" : "left",
                        }}
                      >
                        {quote ? quote.quotes : fallbackQuote.quotes}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          variant="h4"
                          sx={{
                            display: "block",
                            mt: 2,
                            fontStyle: "italic",
                            color: "grey.700",
                            textAlign: isMobile ? "center" : "left",
                          }}
                        >
                          {quote ? `– ${quote.author}` : `– ${fallbackQuote.author}`}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    flex: 0,
                    textAlign: "center",
                    width: isMobile ? "100%" : "50%",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      borderRadius: 2,
                      width: "224px",
                      height: "224px",
                      objectFit: "contain",
                      margin: "auto",
                    }}
                    // image={quote ? quote.img_url : fallbackQuote.img_url}
                    image={img}
                    alt="Author's Image"
                  />
                </Box>
              </Box>
            </div>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: 2,
                py: 1,
                mx: 0,
                my: 0,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: isMobile ? 0 : 1.5,
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                {/* <Button
                  variant="outlined"
                  color="error"
                  onClick={handleClose}
                  sx={{ mb: isMobile ? 1 : 0 }}
                >
                  Close
                </Button> */}
                <Button
                  variant="contained"
                  onClick={handleRead}
                  startIcon={<WysiwygIcon />}
                >
                  Read
                </Button>
              </Box>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default QuoteDaily;