import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import PdfViewerPage from '../Collab-community-views/dashboard/wellness-program/PdfViewerPage'
import { element } from "prop-types";

// const CharityReport = Loadable(lazy(() => import('Collab-community-views/dashboard/CharityReport')));
const PersonalDashboard = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Personal-Dashboard"))
);
const Profile = Loadable(lazy(() => import("Collab-community-views/profile")));
const Follower = Loadable(
  lazy(() => import("Collab-community-views/FollowersPage"))
);
const CollabProfile = Loadable(
  lazy(() => import("Collab-community-views/profile"))
);
const CommunityDashboard = Loadable(
  lazy(() => import("Collab-community-views/CommunityDashboard"))
);
const QuantamQuiz = Loadable(
  lazy(() => import("Collab-community-views/dashboard/QuantamQuiz"))
);
const Habits = Loadable(
  lazy(() => import("Collab-community-views/dashboard/HabitNewUI"))
);
const Learning = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Learning"))
);
const PersonalDashboardNew = Loadable(
  lazy(() =>
    import(
      "Collab-community-views/dashboard/Personal-Dashboard/PersonalDashboardNew"
    )
  )
);
const StockAlert = Loadable(
  lazy(() => import("Collab-community-views/dashboard/StockAlert_new"))
);
const AllWatchlist = Loadable(
  lazy(() =>
    import("Collab-community-views/dashboard/Personal-Dashboard/AllWatchlist")
  )
);
const AllCore = Loadable(
  lazy(() =>
    import("Collab-community-views/dashboard/Personal-Dashboard/AllCore")
  )
);
const Register1 = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Register"))
);
const Login1 = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);
const Forgotpassword1 = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ForgotPassword"))
);
const Events = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Event"))
);
const Help = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Help"))
);
const Resources = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Resources"))
);
const HallOfFame = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Hall_of_Fame"))
);
const CoreAudit = Loadable(
  lazy(() => import("Collab-community-views/dashboard/CoreAudit"))
);
const RecommendedWatchlist = Loadable(
  lazy(() => import("Collab-community-views/dashboard/RecommendedWatchlist"))
);
const SWatchlist = Loadable(
  lazy(() =>
    import("Collab-community-views/dashboard/Personal-Dashboard/SWatchlist")
  )
);
const Users = Loadable(lazy(() => import("Adminviews/dashboard/Users")));
const Utilization = Loadable(
  lazy(() => import("Adminviews/dashboard/Utilization"))
);
const QuantumGroup = Loadable(
  lazy(() => import("Adminviews/dashboard/QuantumGroup"))
);
const WatchList = Loadable(
  lazy(() => import("Adminviews/dashboard/WatchList"))
);
const CharityReport = Loadable(
  lazy(() => import("Adminviews/dashboard/CharityReport"))
);
const SateliteWatchlist = Loadable(
  lazy(() => import("Collab-community-views/dashboard/SatelliteWatchlist"))
);
const ComingSoon = Loadable(
  lazy(() => import("Collab-community-views/ComingSoon"))
);
const ContactUS = Loadable(
  lazy(() => import("Collab-community-views/contact-us"))
);
const CoreWatchlist = Loadable(
  lazy(() => import("Collab-community-views/dashboard/CoreWatchlist"))
);
const User_Management = Loadable(
  lazy(() => import("Collab-community-views/dashboard/User_management"))
);
const Report = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Trace"))
);
const Event_Management = Loadable(
  lazy(() => import("Adminviews/dashboard/Event_management"))
);
const Help_Response = Loadable(
  lazy(() => import("Collab-community-views/dashboard/HelpResponse"))
);
const Help_Management = Loadable(
  lazy(() => import("Adminviews/dashboard/Help_management"))
);
const Satelite = "satelite";
const Core = "core";
const Core_Watchlist = Loadable(
  lazy(() => import("Adminviews/dashboard/CoreWatchlist"))
);
const CactusWatchlist_Admin = Loadable(
  lazy(() => import("Adminviews/dashboard/CatcusWatchlist"))
);
const CactusWatchlist = Loadable(
  lazy(() =>
    import("Collab-community-views/dashboard/CatcusWatchlist/Index.js")
  )
);

const Excel_Export = Loadable(
  lazy(() =>
    import("Collab-community-views/dashboard/ExcelExport/Excel_Export.js")
  )
);
const EodDownload = Loadable(lazy(() => import("Adminviews/dashboard/EOD")));

const Onboarding = Loadable(
  lazy(() => import("Collab-community-views/dashboard/OnBoarding"))
);
const Calculator = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Calculator"))
);
const QicAdmin = Loadable(lazy(() => import("Adminviews/dashboard/QIC")));
const AdminQicUser = Loadable(
  lazy(() => import("Adminviews/dashboard/QIC/Table/User"))
);
const AdminQicTeam = Loadable(
  lazy(() => import("Adminviews/dashboard/QIC/Table/Team"))
);
const ShortlistTable = Loadable(
  lazy(() => import("Collab-community-views/QIC/Team/ShortListTable"))
);
const QuantumInvestingChampion = Loadable(
  lazy(() => import("Collab-community-views/QIC"))
);
const EventQicDashboard = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event"))
);
const Stage1 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage1"))
);
const Stage2 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage2"))
);
const Stage3 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage3"))
);
const Stage4 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage4"))
);
const Stage5 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage5"))
);
const Stage6 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage6"))
);
const Stage7 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage7"))
);
const Stage8 = Loadable(
  lazy(() => import("Collab-community-views/QIC/Event/Stages/Stage8"))
);
const Semi_Finalists = Loadable(
  lazy(() => import("Collab-community-views/QIC/Winners/index.js"))
);
const QicAdminCreateTeam = Loadable(
  lazy(() => import("Adminviews/dashboard/QIC/CreateTeam"))
);
const StageTable = Loadable(
  lazy(() => import("Adminviews/dashboard/QICEvent/StageTable"))
);
const QicEvent = Loadable(lazy(() => import("Adminviews/dashboard/QICEvent")));
const Quiz = Loadable(
  lazy(() =>
    import("Collab-community-views/QIC/Business_Ranking_Calculator/Quiz")
  )
);
const VotingAdmin = Loadable(
  lazy(() => import("Adminviews/dashboard/QICEvent/Voting/Index"))
);
const QicWinnerShortlistTable = Loadable(
  lazy(() => import("Adminviews/dashboard/QIC/WinnerShortlistTable"))
);
const Demo_video_link = Loadable(
  lazy(() => import("Adminviews/dashboard/upload_demo_video_link/index"))
);
const CoreIdeas = Loadable(
  lazy(() => import("Collab-community-views/dashboard/SectorDashboard/index"))
);
const MarketCap = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Market"))
);
const NSEIndex = Loadable(
  lazy(() => import("Collab-community-views/dashboard/NSEIndex"))
);
const MyBooks = Loadable(
  lazy(() => import("Collab-community-views/dashboard/MyBooksHtml"))
);


const CommunityNotes = Loadable(
  lazy(() => import("Collab-community-views/dashboard/StockoPediaNew/index.js"))
);
const PortfolioSizing = Loadable(
  lazy(() => import("Collab-community-views/dashboard/PortfolioSizingCal/index.js"))
);
const PyramidCalculator = Loadable(
  lazy(() => import("Collab-community-views/dashboard/PyramidCalculator/index.js"))
);
const PortfolioAccelerator = Loadable(
  lazy(() => import("Collab-community-views/dashboard/PortfolioAccelerator/index.js"))
);

// Book Hive
const BookHive = Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive/index.js')));
const AddBookHive =Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive_Management/ADD/AddBookHive.js')));
const EditBookHive =Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive_Management/EDIT/EditBookHive.js')));
const Sector = "SectorWatchList";

const SectorDashboardWatchlist=Loadable(lazy(()=>import('Collab-community-views/dashboard/SectorDashboardWatchList/component/AllWatchlist.js')));
const SectorDashboard = Loadable(
  lazy(() => import("Collab-community-views/dashboard/SectorDashboard/index"))
);

// Sector WatchList
const SectorWatchList = Loadable(
  lazy(() => import("Collab-community-views/dashboard/SectorWatchList/index.js"))
);

// Community Notes
const CommunityNotesAdmin = Loadable(
  lazy(() => import("Adminviews/dashboard/CommunityNotesAdmin"))
);

// const CommunityNotesCompanyManagement = Loadable(
//   lazy(() => import("Adminviews/dashboard/CMCompanyManagement"))
// );

const CommunityNotesManagement = Loadable(
  lazy(() => import("Adminviews/dashboard/CMCompanyManagement"))
);


const CommunityNotesContributors = Loadable(
  lazy(() => import("Collab-community-views/dashboard/CommunityNotesContributors"))
);

const IdeaSatellite=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSatellite/index.js')));
const ValuationCalculator = Loadable(
  lazy(() => import("Collab-community-views/dashboard/ValuationCalculator/index.js"))
);

const Quiz2 = Loadable(
  lazy(() =>
    import("Collab-community-views/QIC/Strong_Calculator/Quiz")
  )
);
const IdeaSatelliteOne=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSatelliteOne/index.js')));
const IdeaSector=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSector/index.js')));

const WellnessProgram=Loadable(
  lazy(()=>import('Collab-community-views/dashboard/wellness-program/WellnessProgram.js'))
);

const Admin_WellnessEvent = Loadable(lazy(()=>import('../Adminviews/dashboard/WellnessEvent/index.js')))
const QICComingSoon = Loadable(lazy(() => import("Collab-community-views/dashboard/qic_CommingSoon")));


const DhruvaQuickGuide = Loadable(lazy(() => import("Collab-community-views/dashboard/QuickGuide/index.js")));

const QuantumInvestingChampion2025 = Loadable(lazy(() => import("Collab-community-views/QIC")));
const QICLIVE = Loadable(lazy(() => import("Collab-community-views/QIC/QIC_LIVE_message/index.js")));


const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/Profile",
      element: <Profile />,
    },
    {
      path: "/Personal-Dashboard",
      element: <PersonalDashboard />,
    },
    {
      path: "/profile/:tab",
      element: <CollabProfile />,
    },
    {
      path: "/CommunityDashboard",
      element: <CommunityDashboard />,
    },
    {
      path: "/QuantamQuiz",
      element: <QuantamQuiz />,
    },
    {
      path: "/Habits",
      element: <Habits />,
    },
    {
      path: "/Learning",
      element: <Learning />,
    },
    {
      path: "/PersonalDashboardNew",
      element: <PersonalDashboardNew />,
    },
    {
      path: "/Watchlist",
      element: <SWatchlist />,
    },
    {
      path: "/CoreWatchlist",
      element: <CoreWatchlist WatchlistType={Satelite} />,
    },
    {
      path: "/CactusWatchlist",
      element: <CactusWatchlist WatchlistType={Satelite} />,
    },
    {
      path: "/recommended_watchlist",
      element: <RecommendedWatchlist WatchlistType={Satelite} />,
    },
    {
      path: "/StockAlert",
      element: <StockAlert />,
    },
    {
      path: "/followerspage/:tab/:Friendid",
      element: <Follower />,
    },
    {
      path: "/AllWatchlist",
      element: <AllWatchlist />,
    },
    {
      path: "/AllCore",
      element: <CoreAudit />,
    },
    {
      path: "/Events",
      element: <Events />,
    },
    {
      path: "/contact-us",
      element: <ContactUS />,
    },
    {
      path: "/Resources",
      element: <Resources />,
    },
    {
      path: "/Hall_Of_Fame",
      element: <HallOfFame />,
    },
    {
      path: "/Reports",
      element: <Users />,
    },
    {
      path: "/UtilityReport",
      element: <Report />,
    },
    {
      path: "/Utilization",
      element: <Utilization />,
    },
    {
      path: "/QuantumGroup",
      element: <QuantumGroup />,
    },
    {
      path: "/WatchListReport",
      element: <WatchList />,
    },
    {
      path: "/CharityReport",
      element: <CharityReport />,
    },
    {
      path: "/TrendingWatchList",
      element: <CommunityDashboard />,
    },
    {
      path: "/SateliteWatchlist",
      element: <SateliteWatchlist WatchlistType={Satelite} />,
    },
    {
      path: "/CoreWatchlist",
      element: <SateliteWatchlist WatchlistType={Core} />,
    },
    {
      path: "/User_Management",
      element: <User_Management />,
    },
    {
      path: "/Event_Management",
      element: <Event_Management />,
    },
    {
      path: "/HelpResponse",
      element: <Help_Response />,
    },
    {
      path: "/Help_Management",
      element: <Help_Management />,
    },
    {
      path: "/Core_Watchlist",
      element: <Core_Watchlist />,
    },
    {
      path: "/CactusWatchlist_Admin",
      element: <CactusWatchlist_Admin />,
    },
    {
      path: "/Excel_Export",
      element: <Excel_Export />,
    },
    {
      path: "/eod_download",
      element: <EodDownload />,
    },
    {
      path: "/Onboarding",
      element: <Onboarding />,
    },
    {
      path: "/calculator",
      element: <Calculator />,
    },
    {
      path: "/qic_admin",
      element: <QicAdmin />,
    },
    {
      path: "/Admin/Qic/User",
      element: <AdminQicUser />,
    },
    {
      path: "/Admin/Qic/Team",
      element: <AdminQicTeam />,
    },
    {
      path: "/User/Qic/Team",
      element: <ShortlistTable />,
    },
    {
      path: "/qic/dashboard",
      element: <QuantumInvestingChampion />,
    },
    {
      path: "/qic/dashboard/event",
      element: <EventQicDashboard />,
    },
    {
      path: "/qic/dashboard/event/stage1",
      element: <Stage1 />,
    },
    {
      path: "/qic/dashboard/event/stage2",
      element: <Stage2 />,
    },
    {
      path: "/qic/dashboard/event/stage3",
      element: <Stage3 />,
    },
    {
      path: "/qic/dashboard/event/stage4",
      element: <Stage4 />,
    },
    {
      path: "/qic/dashboard/event/stage5",
      element: <Stage5 />,
    },
    {
      path: "/qic/dashboard/event/stage6",
      element: <Stage6 />,
    },
    {
      path: "/qic/dashboard/event/stage7",
      element: <Stage7 />,
    },
    {
      path: "/qic/dashboard/event/stage8",
      element: <Stage8 />,
    },
    {
      path: "/qic/dashboard/winners",
      element: <Semi_Finalists />,
    },
    {
      path: "/qic_event",
      element: <QicEvent />,
    },
    {
      path: "/qic_admin/createteam",
      element: <QicAdminCreateTeam />,
    },
    {
      path: "/qic_event/stages",
      element: <StageTable />,
    },
    {
      path: "/qic/businessranking_calculator",
      element: <Quiz />,
    },
    {
      path: "/qic/Strong_Calculator",
      element: <Quiz2 />,
    },
    {
      path: "/qic/setting_qicvotes",
      element: <VotingAdmin />,
    },
    {
      path: "/Admin/Qic/WinnerShortlist",
      element: <QicWinnerShortlistTable />,
    },
    {
      path: "/demo_video_link",
      element: <Demo_video_link />,
    },
    {
      path: "/CoreIdeas",
      element: <CoreIdeas />,
    },
    {
      path: "/Market",
      element: <MarketCap />,
    },
    {
      path: "/NSEIndex",
      element: <NSEIndex />,
    },
    {
      path:"mybooks",
      element:<MyBooks/>
    },
    {
      path: "/CommunityNotes",
      element: <CommunityNotes/>,
    },
    {
      path: "/Calculator/PortfolioSizing",
      element: <PortfolioSizing />,
    },
    {
      path: "/Calculator/Pyramid",
      element: <PyramidCalculator />,
    },
    {
      path: "/Calculator/Portfolio_Accelerator",
      element: <PortfolioAccelerator />,
    },
    {
      path: '/BookHive',
      element: <BookHive/>
  },
  {
    path:'/AddBookHive',
    element:<AddBookHive/>
},
{
    path:'/EditBookHive',
    element:<EditBookHive/>
},
{
  path: "/SectorWatchList",
  element: <SectorWatchList WatchlistType={Sector} />,
},
{
  path:'/SectorDashboardWatchlist',
  element:<SectorDashboardWatchlist/>
},
{
  path: "/SectorDashboard",
  element: <SectorDashboard />,
},
{
  path:'/CommunityNotesAdmin',
  element:<CommunityNotesAdmin/>
},
{
  path:'/CommunityNotesManagement',
  element:<CommunityNotesManagement/>
},
// {
//   path:'/CommunityNotesCompanyManagement',
//   element:<CommunityNotesCompanyManagement/>
// },
{
  path:'/CommunityNotesContributors',
  element:<CommunityNotesContributors/>
},
{
  path:'/IdeaSatellite',
  element:<IdeaSatellite/>
},
{
  path: "/Calculator/Valuation_Calculator",
  element: <ValuationCalculator />,
},
  {
      path: "/IdeaSatelliteOne",
      element: <IdeaSatelliteOne />,
    },
    {
      path: "/IdeaSector",
      element: <IdeaSector />,
    },
    {
      path:'/WellnessProgram',
      element:<WellnessProgram/>
    },
    {
      path:"/pdf-viewer",
      element:<PdfViewerPage />
    },
    {
      path:"/WellnessEvent",
      element:<Admin_WellnessEvent />
    },
	  {
      path:"/QICComingSoon",
      element:<QICComingSoon />
    },
    {
      path:"/quickguide",
      element:<DhruvaQuickGuide />
    },
    {
      path: "/qic/dashboard2025",
      element: <QuantumInvestingChampion2025 />,
    },
    {
      path: "/qic/live",
      element: <QICLIVE />,
    },
  ],
};

export default MainRoutes;
