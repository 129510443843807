// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "Dashboard",
  title: <FormattedMessage id="Dashboard" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "Personal-Dashboard",
      title: <FormattedMessage id="Personal Dashboard" />,
      type: "item",
      url: "/Personal-Dashboard",
      icon: icons.IconPictureInPicture,
      breadcrumbs: false,
    },
    {
      id: "Community Dashboard",
      title: <FormattedMessage id="Community Dashboard" />,
      type: "item",
      url: "/CommunityDashboard    ",
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
    //  {
    //     id: 'Alerts Dashboard',
    //     title: <>Alerts Dashboard <sup style={{color:'red'}}><b>(Beta)</b></sup></> ,
    //     type: 'item',
    //     url: '/StockAlert',
    //     icon: icons.IconShieldLock,
    //     breadcrumbs: false
    //  },
    //   {
  ],
};

export default dashboard;
