// third-party
import { FormattedMessage } from 'react-intl';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
// assets
import { IconDashboard, IconDeviceAnalytics,IconBrandChrome,IconHelp,IconSitemap,IconTools,IconShieldLock,IconBook, IconBooks } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconTools,
    IconShieldLock,
    IconBook,
    IconBooks,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Resource = {
    id: 'Resource',
    title: <FormattedMessage id="Resources" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        // {
        //     id: 'Learning path',
        //     title: <FormattedMessage id="Learning Videos" />,
        //     type: 'item',
        //     url: '/Learning',
        //     icon: icons.IconTools,
          
        //     breadcrumbs: false
        // },
        // {
        //     id: 'Learning Videos ',
        //     title: <FormattedMessage id="YouTube Videos" />,
        //     type: 'item',
        //     url: 'https://www.youtube.com/user/mashranivivek',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // },
		// {
        //     id: 'School for Learning ',
        //     title: <FormattedMessage id="School for Learning" />,
        //     type: 'item',
        //     url: 'https://school.vivekmashrani.com/ ',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false,
        //     external: true,
        //     target: true
        // },
        {
            id: 'My Books',
            title: <FormattedMessage id="My Books" />,
            type: 'item',
            url: '/mybooks',
            icon: icons.IconBooks,
          
            breadcrumbs: false
        },
        // {
        //     id: 'You can compound',
        //     title: <FormattedMessage id="You can compound" />,
        //     type: 'item',
        //     url: 'https://youcancompound.com/',
        //     icon: icons.IconSitemap,
        //     external: true,
        //     target: true
        // },
        // {
        //     id: 'action!',
        //     title: <FormattedMessage id="Action!" />,
        //     type: 'item',
        //     url: 'https://finaction.in/',
        //     icon: icons.IconBook,
        //     external: true,
        //     target: true
        // },
        {
            id: 'Resources',
            title: <FormattedMessage id="References" />,
            type: 'item',
            url: '/Resources',
            icon: icons.IconShieldLock,
            breadcrumbs: false
        }       
    ]
    
};

export default Resource;
