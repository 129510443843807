import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    // <Link component={RouterLink} to={DASHBOARD_PATH}>
      <Typography variant='h1'>Dhruva</Typography>
    // </Link>
);

export default LogoSection;
