// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
  IconChartInfographic,
  IconSquareCheck,
  IconClipboardCheck,
  IconChartArcs,
  IconList,
  IconCalendarEvent
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
  IconChartInfographic,
  IconSquareCheck,
  IconClipboardCheck,
  IconChartArcs,
  IconList,
  IconCalendarEvent,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Stocks = {
  id: "Stocks",
  title: <FormattedMessage id="Stocks" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "Sector Watchlist",
      title: <FormattedMessage id="Sector Watchlist" />,
      type: "item",
      url: "/SectorWatchList",
      icon: icons.IconList,
      breadcrumbs: false,
    },
    {
      id: "Satellite Watchlist",
      title: <FormattedMessage id="Satellite Watchlist" />,
      type: "item",
      url: "/SateliteWatchlist",
      icon: icons.IconSquareCheck,
      breadcrumbs: false,
    },
    {
      id: 'Core Watchlist',
      title: <FormattedMessage id="Core Watchlist" />,
      type: 'item',
      url: '/CoreWatchlist',
      icon: icons.IconChartArcs,
      breadcrumbs: false
   },
    {
      id: "Core Audit",
      title: <FormattedMessage id="Core Audit" />,
      type: "item",
      url: "/AllCore",
      icon: icons.IconChartInfographic,
      breadcrumbs: false,
    },
    {
      id: "Stockopedia",
      title: <FormattedMessage id="Community Notes" />,
      type: "item",
      url: "/CommunityNotes",
      icon: icons.IconCalendarEvent,
      breadcrumbs: false,
    },
    {
      id: "Alerts",
      title: <>Alerts<sup style={{color:'red'}}><b>(Beta)</b></sup></> ,
      type: "item",
      url: "/StockAlert",
      icon: icons.IconShieldLock,
      breadcrumbs: false,
    },
   
   
  ],
};

export default Stocks;
