// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconBook,IconDeviceAnalytics,IconClipboardCheck, IconPictureInPicture, IconForms,IconChartArcs,IconDatabaseExport,IconApps,IconCalendarEvent,IconUsers,IconUserX,IconVideo} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconClipboardCheck,
    IconPictureInPicture,
    IconForms,
    IconChartArcs,
    IconDatabaseExport,
    IconApps,
    IconUsers,
    IconUserX,
    IconCalendarEvent,
    IconVideo	,
    IconBook
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Admin = {
    id: 'Admin Dashboard',
    title: <FormattedMessage id="Admin Dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
	children: [
        
        {
            id: 'Manage users',
            title: <FormattedMessage id="Manage users" />,
            type: 'item',
            url: '/User_Management',
            icon: icons.IconForms,
            breadcrumbs: false
        },
          {
      id: "qic",
      title: <FormattedMessage id="QIC" />,
      type: "collapse",
      url: "/User_Management",
      icon: icons.IconApps,
      breadcrumbs: false,
      children: [
        {
          id: "Qicusermanagement",
          title: <FormattedMessage id="User Management" />,
          type: "collapse",
          icon: icons.IconUsers,
          url: "/qic_admin",
          breadcrumbs: false,
          children: [
            {
              id: "createTeam",
              title: <FormattedMessage id="Create Team" />,
              type: "item",
              url: "/qic_admin/createteam",
              breadcrumbs: false,
            },
            {
              id: "assignteammember",
              title: <FormattedMessage id="Assign Team Members" />,
              type: "item",
              url: "/Admin/Qic/User",
              breadcrumbs: false,
            },
            {
              id: "updateteamstatus",
              title: <FormattedMessage id="Stage Shortlist" />,
              type: "item",
              url: "/Admin/Qic/Team",
              breadcrumbs: false,
            },
            {
              id: "winnershortlist",
              title: <FormattedMessage id="Winner Shortlist" />,
              type: "item",
              url: "/Admin/Qic/WinnerShortlist",
              breadcrumbs: false,
            },
          ],
        },
        {
          id: "QicEvent",
          title: <FormattedMessage id=" Event Management" />,
          type: "collapse",
          icon: icons.IconCalendarEvent,
          url: "/qic_event",
          breadcrumbs: false,
          children: [
            {
              id: "createEvent",
              title: <FormattedMessage id="Create Event" />,
              type: "item",
              url: "/qic_event",
              breadcrumbs: false,
              children: [],
            },
            {
              id: "assignstages",
              title: <FormattedMessage id="Assign Stages" />,
              type: "item",
              url: "/qic_event/stages",
              breadcrumbs: false,
            },
            {
              id: "businessrankingqic",
              title: <FormattedMessage id="Setting QIC Votes" />,
              type: "item",
              url: "/qic/setting_qicvotes",
              breadcrumbs: false,
            },
          ],
        },
      ],
    },
        {
            id: 'Report',
            title: <FormattedMessage id="Report" />,
            type: 'item',
            url: '/UtilityReport',
            icon: icons.IconForms,
            breadcrumbs: false
        },
        {
            id: 'Event Management',
            title: <FormattedMessage id="Event Management" />,
            type: 'item',
            url: '/Event_Management',
            icon: icons.IconPictureInPicture,
            breadcrumbs: false
        },
        {
          id: 'WellnessEvent',
          title: <FormattedMessage id="Wellness Event" />,
          type: 'item',
          url: '/WellnessEvent',
          icon: icons.IconPictureInPicture,
          breadcrumbs: false
        },
        {
            id: 'Help Management',
            title: <FormattedMessage id="Help Management" />,
            type: 'item',
            url: '/Help_Management',
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        },
        {
            id: 'Core Watchlist',
            title: <FormattedMessage id="Core Watchlist" />,
            type: 'item',
            url: '/Core_Watchlist',
            icon: icons.IconChartArcs,
            breadcrumbs: false
        },
        {
          id: 'portal_walkthrough_links',
          title: <FormattedMessage id="Dhruva walkthrough links" />,
          type: 'item',
          url: '/demo_video_link',
          icon: icons.IconVideo,
          breadcrumbs: false
      },
        // {
        //     id: 'Cactus Watchlist',
        //     title: <FormattedMessage id="Cactus Watchlist" />,
        //     type: 'item',
        //     url: '/CactusWatchlist_Admin',
        //     icon: icons.IconClipboardCheck,
        //     breadcrumbs: false
        // },
          // {
        //     id: 'Excel Export',
        //     title: <FormattedMessage id="Excel Export" />,
        //     type: 'item',
        //     url: '/Excel_Export',
        //     icon: icons.IconDatabaseExport,
        //     breadcrumbs: false
        // },
        {
            id: 'eod download',
            title: <FormattedMessage id="EOD" />,
            type: 'item',
            url: '/eod_download',
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        },
      {
        id: 'Community Notes Admin',
        title: <FormattedMessage id="Community Notes Admin" />,
        type: 'item',
        url: '/CommunityNotesManagement',
        icon: icons.IconClipboardCheck,
        breadcrumbs: false
    },
     {
        id: 'Community Notes Contributors',
        title: <FormattedMessage id="CM Notes Publish" />,
        type: 'item',
        url: '/CommunityNotesContributors',
        icon: icons.IconClipboardCheck,
        breadcrumbs: false
    },
	  // {
    //       id: 'Community Notes Company Management',
    //       title: <FormattedMessage id="CM Company Management" />,
    //       type: 'item',
    //       url: '/CommunityNotesCompanyManagement',
    //       icon: icons.IconClipboardCheck,
    //       breadcrumbs: false
    //   },
	]
};

export default Admin;
