// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const	Charity = {
    id: 'Charity',
    title: <FormattedMessage id="Charity" />,
    icon: icons.IconDashboard,
    type: 'group',
    
};

export default Charity;
