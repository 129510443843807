import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Core viewer styles
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Default layout styles
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import back icon
import { Card, Typography } from '@mui/material'; // Import Card and Typography components

const PdfViewerPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const { docLink } = location.state || { docLink: null };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ padding: '10px' }}>
      {/* Button Container */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button
          onClick={() => navigate(-1)} // Navigate back to the previous page
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            cursor: 'pointer',
            backgroundColor: '#1976d2', // Initial button color
            color: '#fff', // Initial text color
            border: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s, transform 0.3s, margin-left 0.3s', // Added transform transition
            marginLeft: '0', // Start position
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#1976d2'; // Change background to white on hover
            e.currentTarget.style.color = '#fff'; // Change text color to blue
            e.currentTarget.style.transform = 'scale(1.05)'; // Scale effect on hover
            e.currentTarget.style.marginLeft = '5px'; // Slide effect
          }} 
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)'; // Reset scale
            e.currentTarget.style.marginLeft = '0'; // Reset position
          }}
        >
          <ArrowBackIcon style={{ marginRight: '8px' }} /> {/* Back icon */}
          Back to Wellness Program
        </button>
      </div>

      {/* PDF Viewer Card */}
      <Card
        style={{
          padding: '10px',
          transition: 'background-color 0.3s',
          backgroundColor: '#fff',
        }}
      >
        {docLink ? (
          <div style={{ height: '750px' }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              <Viewer fileUrl={docLink} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          </div>
        ) : (
          <Typography variant="h6" align="center" color="error">
            No document link provided.
          </Typography>
        )}
      </Card>
    </div>
  );
};

export default PdfViewerPage;
