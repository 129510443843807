// third-party
import { FormattedMessage } from 'react-intl';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
// assets
import { IconDashboard, IconDeviceAnalytics, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUser
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Profile = {
    id: 'Dashboard',
    title: <FormattedMessage id="Dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
       
        {
            id: 'My Profile',
            title: <FormattedMessage id="My Profile" />,
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
         },
         {
            id: "My Community",
            title: <FormattedMessage id="My Community" />,
            type: "item",
            url: "/CommunityDashboard",
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
          },
          {
            id: 'Hall of Fame',
            title: <FormattedMessage id="Hall of Fame" />,
            type: 'item',
            url: '/Hall_Of_Fame',
            icon: MilitaryTechIcon,
            breadcrumbs: false
        },
       
    ]
    
};

export default Profile;
