// NotFound.js
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="Card404">
      <h1>Kindly Login to Access the Requested Feature</h1>
      <p>
        You can access the login page through
        the Button below
      </p>
      <Link to="/" className="btn404">
        <span>
          <ArrowBackIcon />
        </span>
        <span>Go to Login</span>
      </Link>
    </div>
  );
};

export default NotFound;
