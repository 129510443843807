// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardCheck, IconDashboard } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconClipboardCheck,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const CnAdmin = {
    id: 'CN Admin',
    title: <FormattedMessage id="CN Admin" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: "Community Notes Contributors",
            title: <FormattedMessage id="CM Notes Publish" />,
            type: "item",
            url: "/CommunityNotesContributors",
            icon: icons.IconClipboardCheck,
            breadcrumbs: false,
        }
    ]

};

export default CnAdmin;
