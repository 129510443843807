// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics,IconClipboardCheck, IconPictureInPicture, IconForms,IconChartArcs,IconDatabaseExport,IconApps,IconCalendarEvent,IconUsers,IconUserX,IconVideo,IconBook,IconChecklist} from '@tabler/icons';

import Book from "@mui/icons-material/Book";
// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconClipboardCheck,
    IconPictureInPicture,
    IconForms,
    IconChartArcs,
    IconDatabaseExport,
    IconApps,
    IconUsers,
    IconUserX,
    IconCalendarEvent,
    IconVideo,
    IconChecklist,
    IconBook
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const MarketDashboard = {
    id: 'Market Dashboard',
    title: (
      <>
        Market<sup style={{ fontSize: "1.0em" , fontWeight: "bold" }}> AI</sup>{" "}
        <sup style={{ color: "red" }}>
          <b>(Beta)</b>
        </sup>
      </>
    ),
    icon: icons.IconDashboard,
    type: 'group',
	children: [    
        {
                id: 'Market_Outlook',
                title: <FormattedMessage id="Market Outlook" /> ,
                type: 'item',
                url: '/Market',
                icon: icons.IconDashboard,
                breadcrumbs: false
        },
        {
            id: 'Sector_Outlook',
            title: <FormattedMessage id="Sector Outlook" />,
            type: 'item',
            url: '/NSEIndex',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        {
            id: 'BookHive',
            title: <FormattedMessage id="BookHive" />,
            type: 'item',
            url: '/BookHive',
            icon: icons.IconBook,
            breadcrumbs: false
          },
          {
            id: "BookHive Management",
            title: <FormattedMessage id="BookHive Management" />,
            type: "collapse",
            icon: Book,
            breadcrumbs: false,
            children: [
              {
                id: "AddBook",
                title: <FormattedMessage id="Add Book - Category" />,
                type: "item",
                url: "/AddBookHive",
                icon: icons.IconApps,
                breadcrumbs: false,
              },
              {
                id: "EditBook",
                title: <FormattedMessage id="Edit Book" />,
                type: "item",
                url: "/EditBookHive",
                icon: icons.IconApps,
                breadcrumbs: false,
              },
            ]
          },
          {
            id: "CoreIdeas",
            title: <FormattedMessage id="Ideas Core" />,
            type: "item",
            url: "/CoreIdeas",
            icon: icons.IconChecklist,
            breadcrumbs: false,
        },  
        {
          id: 'IdeaSatellite',
          title: <FormattedMessage id="Idea Satellite" /> ,
          type: 'item',
          url: '/IdeaSatellite',
          icon: icons.IconDashboard,
          breadcrumbs: false
  },
  	{
      id: "IdeaSatelliteOne",
      title: <FormattedMessage id="Idea Satellite One" />,
      type: "item",
      url: "/IdeaSatelliteOne",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "IdeaSector",
      title: <FormattedMessage id="Idea Sector" />,
      type: "item",
      url: "/IdeaSector",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
      //   {
      //     id: 'SectorLeaderWatchlistAdmin',
      //     title: <FormattedMessage id="Watchlist Admin" />,
      //     type: 'item',
      //     url: '/SectorDashboardWatchlist',
      //     icon: icons.IconChecklist,
      //     breadcrumbs: false
      // },    
        // {
        //     id: 'Sector Rotation',
        //     title: <FormattedMessage id="Sector Rotation" />,
        //     type: 'item',
        //     url: '/#',
        //     icon: icons.IconChecklist,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'Screener',
        //     title: <FormattedMessage id="Screener" />,
        //     type: "collapse",
        //     icon: icons.IconChecklist,
        //     breadcrumbs: false,
        //     children: [    
        //         {
        //                 id: 'MOMO',
        //                 title:<FormattedMessage id="MOMO" /> ,
        //                 type: 'item',
        //                 url: '/momo',
        //                 icon: icons.IconDashboard,
        //                 breadcrumbs: false
        //         },
        //         {
        //             id: 'PV',
        //             title: <FormattedMessage id="PV" />,
        //             type: 'item',
        //             url: '/pv',
        //             icon: icons.IconChecklist,
        //             breadcrumbs: false
        //         },
               
        //     ]
        // },
        // {
        //     id: 'SubSectorDashboard',
        //     title: <FormattedMessage id="Stocko Pedia" />,
        //     type: 'item',
        //     url: '/#',
        //     icon: icons.IconChecklist,
        //     breadcrumbs: false
        // },         
        // {
        //     id: 'Stockopedia ',
        //     title: <FormattedMessage id="Stockopedia" />,
        //     type: 'item',
        //     url: '/Stockopedia ',
        //     icon: icons.IconChecklist,
        //     breadcrumbs: false
        // },
	]
};

export default MarketDashboard;
