import { createSlice } from "@reduxjs/toolkit";
import { API_URL } from "config";
import axios from "axios";
import { dispatch } from '../index';

const initialState={
    GetDemovideoData:[]
}

const ReloadDemoVideos=createSlice({
    name:'reloadDemovideos',
    initialState,
    reducers:{
        setDemovideoLink(state,action){
                state.GetDemovideoData=action.payload
        }
    }
})
export async function GetDemovideolinks(){
    const responce= await axios.post(API_URL +'/api/getdemovideoLink');
    dispatch(setDemovideoLink(responce.data.data));
    
    }


    export const selectAttendance=(state)=>(state.GetDemovideoData);
    export const{setDemovideoLink} = ReloadDemoVideos.actions;

export default ReloadDemoVideos.reducer