// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconClipboardCheck, IconDashboard, IconDeviceAnalytics,IconChartArcs,IconClipboardList ,IconChartInfographic,IconKey,IconBug,IconPhoneCall,IconSquareCheck, IconHomeQuestion,IconYoga } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
	IconKey,
    IconBug,
    IconPhoneCall,
	IconSquareCheck,
    IconClipboardCheck,
    IconHomeQuestion,
    IconYoga
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const MyActions = {
    id: 'My Actions',
    title: <FormattedMessage id="My Actions" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'Habits',
            title: <FormattedMessage id="Habits" />,
            type: 'item',
            url: '/Habits',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'Quantum Quiz',
            title: <FormattedMessage id="Quantum Quiz" />,
            type: 'item',
            url: '/QuantamQuiz',
            icon: icons.IconBug,
            breadcrumbs: false
        },
        {
            id: 'Events',
            title: <FormattedMessage id="Events" />,
            type: 'item',
            url: '/Events',
            icon: icons.IconPhoneCall,
            breadcrumbs: false
        },
        {
            id: 'WellnessProgram',
            title: <>Wellness</>,
            type: 'item',
            url: '/WellnessProgram',
            icon: icons.IconYoga,
            breadcrumbs: false
        },
        ]
    
};

export default MyActions;
